import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyCfOZ9kv85ibTH9NeQoQr0oKcZNaFAx2wM",
  authDomain: "certificates-ffacb.firebaseapp.com",
  databaseURL: "https://certificates-ffacb.firebaseio.com",
  projectId: "certificates-ffacb",
  storageBucket: "certificates-ffacb.appspot.com",
  messagingSenderId: "126120702745",
  appId: "1:126120702745:web:03bbd33222dbdbe4350b88"
};

export const myFirebase = firebase.initializeApp(firebaseConfig);
const baseDb = myFirebase.firestore();
export const db = baseDb;
